import React from 'react';
import TopBar from 'components/navigation/TopBar';
import Footer from 'components/navigation/Footer';
import Loading from 'components/modals/Loading';
import { appSettings } from 'config/app';
import { Toaster } from 'react-hot-toast';
import { WebSocket } from 'helpers/socket';

const Horizontal = ({ children }) => {

  return (
    <div className="flex flex-col items-center w-screen h-screen bg-white dark:bg-slate-950 text-3xl font-extralight text-gray-700 dark:text-white">
      <Loading />
      <Toaster
        position="top-center"
        containerClassName="text-2xl"
        toastOptions={appSettings.toast}
      />
      {appSettings.socket.active &&
        <div className="absolute top-5 start-5">
          <WebSocket showIconStatus />
        </div>
      }
      <div className="w-full pt-10">
        <TopBar />
      </div>
      <div className="w-full h-[75%] [&>*]:h-full [&>*]:w-full px-36">
        {children}
      </div>
      <div className="w-full h-[10%] pb-10">
        <Footer />
      </div>
    </div>
  )
}

export default Horizontal;