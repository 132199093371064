import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateLanguage } from 'store/apps/app';
import { appSettings } from 'config/app';
import LanguageButton from 'components/common/LanguageButton';
import { Icon } from '@iconify/react';

const TopBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { language } = useSelector((state) => state.app);

  const { pathname } = useLocation();

  const [dark, setDark] = useState(true);

  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    dispatch(updateLanguage(lang));
    i18n.changeLanguage(lang);
  }

  const toggleTheme = () => {
    const root = window.document.documentElement

    if (root.classList.contains('dark')) {
      root.classList.remove('dark')
      root.classList.add('light')
      setDark(false)
    } else {
      root.classList.remove('light')
      root.classList.add('dark')
      setDark(true)
    }
  }

  useEffect(() => {
    window.document.documentElement.classList.add('dark')
    i18n.changeLanguage('pt');
  }, [])

  return (
    <div className="grid grid-cols-12 px-36 gap-x-10">
      <div className="col-span-5 flex justify-center w-full rounded-lg overflow-hidden">
        <img
          className="w-3/4"
          src={appSettings.logo.url}
          alt={appSettings.logo.alt}
          onClick={() => navigate('/home')}
        />
      </div>
      <div className="col-span-6 flex justify-end items-center w-full gap-x-5">
        <LanguageButton outlined={language !== 'pt'} onClick={() => changeLanguage('pt')}>
          PT
        </LanguageButton>
        <LanguageButton outlined={language !== 'en'} onClick={() => changeLanguage('en')}>
          EN
        </LanguageButton>
        <div onClick={toggleTheme} className="flex justify-center items-center rounded-lg font-light text-4xl p-5 border-2 border-primary text-primary">
          <Icon icon={dark ? 'lucide:sun' : 'lucide:moon'} className="text-primary" />
        </div>
      </div>
      <div className="col-span-1 flex justify-end items-center">
        {pathname !== '/home'
          ?
          <div className="border-2 border-primary text-4xl p-5  rounded-lg font-light" onClick={() => navigate('/home')}>
            <Icon icon="lucide:home" className="text-primary" />
          </div>
          :
          <div className="border-2 border-primary text-4xl p-5  rounded-lg font-light" onClick={() => navigate('/create')}>
            <Icon icon="lucide:user-round" className="text-primary" />
          </div>
        }
      </div>
    </div>
  )
}

export default TopBar;