import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateLoading } from '../app';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import api from 'helpers/api'

// Create an async thunk for verifying a PIN
export const getSizes = createAsyncThunk('place/sizes', async (params, { dispatch }) => {

  try {
    dispatch(updateLoading(true));

    const response = await api.get(`screen01/locker/sizes/${process.env.REACT_APP_LOCATION_ID}`);

    if (!response.data.Result) {
      toast.error(t(`errors.${response.data.Code}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
});


export const placeSlice = createSlice({
  name: 'place',
  initialState: {
    sizes: false
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSizes.fulfilled, (state, action) => {
      state.sizes = action.payload.Data
    });
  }
})

export default placeSlice.reducer
