import React, { Suspense } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import Layout from "layouts";

const Homepage = React.lazy(() => import(/* webpackChunkName: "views-home" */ "views/home"));
const Create = React.lazy(() => import(/* webpackChunkName: "views-delivery-create" */ "views/delivery/create"));
const Deposit = React.lazy(() => import(/* webpackChunkName: "views-delivery-deposit" */ "views/delivery/deposit"));
const Success = React.lazy(() => import(/* webpackChunkName: "views-delivery-success" */ "views/delivery/success"));
const NotFoundPage = React.lazy(() => import(/* webpackChunkName: "views-404" */ "views/404"));

const Router = () => {

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route index element={<Navigate to="/home" />} />
            <Route exact path="/home" element={<Homepage />} />
            <Route exact path="/create" element={<Create />} />
            <Route exact path="/deposit" element={<Deposit />} />
            <Route exact path="/success" element={<Success />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </Suspense >
  );
};

export default Router;
