import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { updateLoading } from '../app';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import api from 'helpers/api'

// Create an async thunk for create a order
export const createDelivery = createAsyncThunk('delivery/new', async (params, { dispatch }) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  try {
    dispatch(updateLoading(true));

    const response = await api.post(`screen01/delivery/new`, params, config);

    if (!response.data.Result) {
      toast.error(t(`errors.${response.data.Code}`));
      dispatch(updateLoading(false));

      return response?.data;
    }

    dispatch(updateLoading(false));

    return response?.data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
});

// Create an async thunk for check order
export const checkDelivery = createAsyncThunk('delivery/check', async (params, { dispatch }) => {

  try {
    dispatch(updateLoading(true));

    const response = await api.get('screen01/delivery/check?package=' + params);

    if (!response.data.Result) {
      toast.error(t(`errors.${response.data.Code}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
});

// Create an async thunk for store a order
export const storeDelivery = createAsyncThunk('delivery/store', async (params, { dispatch }) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };

  try {
    dispatch(updateLoading(true));

    const response = await api.post(`api/delivery/storePackage`, params, config);

    if (!response.data.Result) {
      toast.error(t(`errors.${response.data.Code}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateLoading(false));

    return response?.data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
});

// Create an async thunk for verifying a PIN
export const verifyPin = createAsyncThunk('delivery/verifyPin', async (params, { dispatch }) => {

  const { pin } = params;

  try {
    dispatch(updateLoading(true));

    const response = await api.get(`api/delivery/pin/verify?pin=${pin}`);

    if (!response.data.Result) {
      toast.error(t(`errors.${response.data.Code}`));
      dispatch(updateLoading(false));

      return false;
    }

    dispatch(updateModal(true));

    dispatch(updateLoading(false));

    return response?.data?.Data;

  } catch (error) {

    dispatch(updateLoading(false));
    toast.error(t('errors.Error'));

    throw error;
  }
});

// Create an async thunk for open assigned locker with PIN
export const openWithPin = createAsyncThunk('delivery/openWithPin', async (params, { dispatch }) => {

  const { pin } = params;

  try {

    const response = await api.get(`api/delivery/pin/pickUp?pin=${pin}`);

    if (!response.data.Result) {

      toast.error(t(`errors.${response.data.Code}`));

      return false;
    }

    toast.success(t('locker.opened'));
    dispatch(updateModal(false));

    return response?.data?.Data;

  } catch (error) {

    toast.error(t('errors.Error'));

    throw error;
  }
});

export const updateModal = createAsyncThunk('appConfig/updateModal', async (params) => {
  return params;
})

// Create an async thunk for reset state
export const resetDelivery = createAsyncThunk('delivery/resetDelivery', async (params, { dispatch }) => {
  return true;
});

export const deliverySlice = createSlice({
  name: 'delivery',
  initialState: {
    selected: false,
    order: {
      selected: false,
      pin: false
    },
    locker: {
      selected: false,
      opened: false
    },
    modal: {
      open: false,
    }
  },
  reducers: {
    clearDelivery: (state, action) => {
      state.selected = false
    }
  },
  extraReducers: builder => {
    builder.addCase(createDelivery.fulfilled, (state, action) => {
      state.selected = action.payload?.Data
    });
    builder.addCase(checkDelivery.fulfilled, (state, action) => {
      state.selected = action.payload?.Data
    });
    builder.addCase(verifyPin.fulfilled, (state, action) => {
      state.locker = {
        opened: false,
        selected: action.payload.locker,
      };
      state.order = {
        selected: action.payload,
        pin: action.meta.arg.pin
      }
    });
    builder.addCase(openWithPin.fulfilled, (state, action) => {
      state.locker.opened = true
    });
    builder.addCase(updateModal.fulfilled, (state, action) => {
      state.modal = {
        open: action.payload
      }
    })
    builder.addCase(resetDelivery.fulfilled, (state, action) => {
      state.locker = {
        opened: false,
        selected: false,
      };
      state.order = {
        selected: false,
        pin: false
      }
    });
  }
})

export const { clearDelivery } = deliverySlice.actions;

export default deliverySlice.reducer;
